import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Roi from "./components/ImageRoi";
import Cameras from "./components/Cameras";

import Login from "./components/Login";

function App() {
  const [usernameSaved, setUsernameSaved] = useState();
  const [passwordSaved, setPasswordSaved] = useState();

  return (<>
    {(usernameSaved != null && passwordSaved != null) ? (<>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
        <Route path='/:project' element={<Cameras username={usernameSaved} password={passwordSaved}/>} />
          <Route path='/:project/:camera' element={<Roi username={usernameSaved} password={passwordSaved}/>} />
        </Routes>
      </BrowserRouter>
    </>) : (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path='/:project/:camera?' element={<Login setUsernameSaved={setUsernameSaved} setPasswordSaved={setPasswordSaved}/>} />
        </Routes>
      </BrowserRouter>
    )}
  </>);
}

export default App;
