import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { List, ListItem, ListItemButton, ListItemText, Typography, Backdrop, CircularProgress } from '@mui/material';

const Cameras = ({ username, password }) => {
  const routeParam = useParams()
  const navigate = useNavigate()
  
  const [cameras, setCameras] = useState()

  const getCameras = async () => {
    const res2 = await fetch(`${window.location.origin}/api/cameras/${routeParam["project"]}`, {
      headers: {"Authorization" : `${username}:${password}`}
    })

    setCameras(await res2.json())
  }

  useEffect(() => {
    getCameras()
  }, [])

  return (<>
    <Typography>{routeParam["project"]}</Typography>

    {cameras ? (
      <List style={{ padding: "0px" }}>
        {(cameras).map((i) => 
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={i} onClick={() => navigate(`./${i}`)} />
            </ListItemButton>
          </ListItem>
        )}
    </List>
    ) : (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <div style={{"display": "flex", "flexFlow": "row", "padding": "20px"}}>
            <CircularProgress />
        </div>
      </Backdrop>
    )}
  </>)
}

export default Cameras