import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ImageRoiDraw from "./ImageRoiDraw";
import { Button } from 'react-bootstrap'
import { Backdrop, CircularProgress  } from '@mui/material'

const Roi = ({ username, password }) => {
    const routeParam = useParams()
    const navigate = useNavigate()

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })

    const [image, setImage] = useState()
    const [roi, setRoi] = useState()
    const [finished, setFinished] = useState(false)

    const mapRef = useRef()

    const onsizeChange = () => {
        if (typeof window !== "undefined") {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }
    }
    
    useEffect(() => {
        window.addEventListener("resize", onsizeChange)

        return () => {
            window.removeEventListener("resize", onsizeChange)
        }
    }, [])

    const fetchImage = async () => {
        const res1 = await fetch(`${window.location.origin}/api/snapshot/${routeParam["project"]}/${routeParam["camera"]}`, {
            headers: {"Authorization" : `${username}:${password}`}
        })
    
        if (res1.status !== 200) {
            alert("The camera is disconnected")
        }

        const blob = await res1.blob()

        const bmp = await createImageBitmap(blob);
        const { width, height } = bmp;

        setImage({
            "blob": blob,
            "width": width,
            "height": height
        })
      
        onsizeChange()

        const res2 = await fetch(`${window.location.origin}/api/roi/${routeParam["project"]}/${routeParam["camera"]}`, {
            headers: {"Authorization" : `${username}:${password}`}
        })
    
        if (res2.status !== 200) {
            alert("Error fetching roi")
        }

        setRoi(await res2.json())
    }

    useEffect(() => {
        fetchImage()
    }, [])

    const reset = () => {
        mapRef.current.reset()
    }

    const clear = () => {
        mapRef.current.clear()
    }

    const update = async () => {
        var area = await mapRef.current.getCoordinates()
        area = area.map((i) => [parseFloat(i[0].toFixed(5)), parseFloat(i[1].toFixed(5))])

        const res = await fetch(
             `${window.location.origin}/api/roi`, {
                method: "POST",
                body: JSON.stringify({
                    "project": routeParam["project"],
                    "camera": routeParam["camera"],
                    "area": JSON.stringify(area)
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization" : `${username}:${password}`
                }
            }
        )

        if (res.status !== 200) {
            alert("Error Updateing roi")
        }

        fetchImage()
    }

    return (<>
        {(image && roi) ? (
            <div style={{ height: windowSize["height"], width: windowSize["width"], display: "flex", flexFlow: "column" }}>
                <Button variant="link" onClick={() => navigate("./..")}>Back</Button>
            
                <div style={{ flexGrow: 1, overflow: "auto" }}>
                    {image && 
                        <ImageRoiDraw
                            mode={(roi.length > 0) ? "Modify" : "Draw"} ref={mapRef}
                            blob={image["blob"]} width={image["width"]} height={image["height"]}
                            area={roi ?? []} setFinishState={(finished) => setFinished(finished)}
                        />
                    }
                </div>

                <div>Current mode: {finished === true ? "Modify": "Draw"}</div>

                <button onClick={() => update(mapRef.current.getCoordinates())}>Apply</button>
                <button onClick={() => reset()}>Reset</button>
                <button onClick={() => clear()}>Clear</button>
            </div>
        ) : (
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                <div style={{"display": "flex", "flexFlow": "row", "padding": "20px"}}>
                    <CircularProgress />
                </div>
            </Backdrop>
        )}
    </>);
}

export default Roi