import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Form } from 'react-bootstrap'
import { Box, Paper } from '@mui/material';

const Login = ({ setUsernameSaved, setPasswordSaved }) => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const routeParam = useParams()

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const onsizeChange = () => {
    if (typeof window !== "undefined") {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
  }

  useEffect(() => {
    window.addEventListener("resize", onsizeChange)

    return () => {
      window.removeEventListener("resize", onsizeChange)
    }
  }, [])

  const login = async (e) => {
    e.preventDefault()
    
    const res1 = await fetch(`${window.location.origin}/api/login/${routeParam["project"]}`, {
      headers: {"Authorization" : `${username}:${password}`}
     })

    if (res1.status !== 200) {
        alert("Invalid login credentai")
        return
    }

    setUsernameSaved(username)
    setPasswordSaved(password)
  }
  
  return (
    <Container fluid className='mainContainer' style={{ width: windowSize["width"], height: windowSize["height"], padding: "10px" }}>
      <Paper elevation={3} style={{ width: "70%", display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "25px", paddingBottom: "25px", paddingLeft: "10px", paddingRight: "10px", maxHeight: "90%", margin: "auto", overflow: "auto" }}>
        <Form onSubmit={login} style={{ width: "100%" }}>
          <Form.Group className="m-3">
          <Form.Label>Username</Form.Label>
          <Form.Control type="text" placeholder="Username" onChange={(e) => setUsername(e.target.value)} required/>
          </Form.Group>

          <Form.Group className="m-3">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required/>
          </Form.Group>

          <Form.Group className="m-3"></Form.Group>

          <Form.Group className="m-3 mt-5">
            <Button type="submit" style={{ width: "100%" }}>
              Login
            </Button>
          </Form.Group>
        </Form>
      </Paper>
    </Container>
  )
}

export default Login